<svelte:options tag="wtz-vis" />

<script>
  let available_stations = ["BRLAS", "BNZG0", "BNZC2"];
  let station = "BRLAS";
  let year = parseInt(new Date().getFullYear());
  let month = parseInt(new Date().getMonth()) + 1;
  let day = parseInt(new Date().getDate());
  let hour = parseInt(new Date().getHours()) - 2;
  let part;
  let month2String;
  let day2String;
  let url;
  let selectedDate,
    selectedTimestamp,
    nextTimpestamp,
    nextDate,
    prevTimestamp,
    prevDate;
  let availableYears = [];
  let availableMonths = [];
  let availableDays = [];
  let availableHours = [];
  let base_url =
    "https://observatory.geophysik.uni-muenchen.de/seismology/wettzell/WWWGifs/";

  for (let y = 2007; y <= parseInt(new Date().getFullYear()); y++) {
    availableYears.push(y);
  }

  for (let m = 1; m <= 12; m++) {
    availableMonths.push(m);
  }

  for (let d = 1; d <= 31; d++) {
    availableDays.push(d);
  }

  for (let h = 0; h <= 23; h++) {
    availableHours.push(h);
  }

  $: {
    console.log("update url");

    selectedDate = new Date(year, month - 1, day, hour, 0, 0);
    selectedTimestamp = Math.floor(selectedDate.getTime() / 1000);

    year = parseInt(new Date(selectedTimestamp * 1000).getFullYear());
    month = parseInt(new Date(selectedTimestamp * 1000).getMonth() + 1);
    day = parseInt(new Date(selectedTimestamp * 1000).getDate());
    hour = parseInt(new Date(selectedTimestamp * 1000).getHours());

    //formatstring for javascript date

    if (month < 10) {
      month2String = "0" + String(month);
    } else {
      month2String = String(month);
    }

    if (day < 10) {
      day2String = "0" + String(day);
    } else {
      day2String = String(day);
    }

    if (hour >= 0 && hour < 6) {
      part = "00";
    } else if (hour >= 6 && hour < 12) {
      part = "06";
    } else if (hour >= 12 && hour < 18) {
      part = "12";
    } else {
      part = "18";
    }
    url =
      base_url +
      station +
      "/" +
      station +
      "_" +
      day2String +
      month2String +
      String(year).slice(-2) +
      "_" +
      part +
      ".gif";
  }

  function goNext() {
    nextTimpestamp = selectedTimestamp + 6 * 60 * 60;
    nextDate = new Date(nextTimpestamp * 1000);
    year = parseInt(nextDate.getFullYear());
    month = parseInt(nextDate.getMonth()) + 1;
    day = parseInt(nextDate.getDate());
    hour = parseInt(nextDate.getHours());
  }

  function goPrev() {
    prevTimestamp = selectedTimestamp - 6 * 60 * 60;
    prevDate = new Date(prevTimestamp * 1000);
    year = parseInt(prevDate.getFullYear());
    month = parseInt(prevDate.getMonth()) + 1;
    day = parseInt(prevDate.getDate());
    hour = parseInt(prevDate.getHours());
  }

  function goNow() {
    let timestamp = Math.floor(Date.now() / 1000);
    let now = new Date((timestamp - 2 * 60 * 60) * 1000);
    year = parseInt(now.getFullYear());
    month = parseInt(now.getMonth()) + 1;
    day = parseInt(now.getDate());
    hour = parseInt(now.getHours());
  }
</script>

<main>
  <form action="" method="GET">
    <label for="station">station:</label>
    <select bind:value={station} name="station" id="station">
      {#each available_stations as astation}
        <option value={astation}>{astation}</option>
      {/each}
    </select>
  </form>
  <form action="" method="GET">
    <label for="year">year:</label>
    <select bind:value={year} name="year" id="year">
      {#each availableYears as ayear}
        <option value={ayear}>{ayear}</option>
      {/each}
    </select>
    <label for="month">month:</label>
    <select bind:value={month} name="month" id="month">
      {#each availableMonths as amonth}
        <option value={amonth}>{amonth}</option>
      {/each}
    </select>
    <label for="day">day:</label>
    <select bind:value={day} name="day" id="day">
      {#each availableDays as aday}
        <option value={aday}>{aday}</option>
      {/each}
    </select>
    <label for="hour">hour:</label>
    <select bind:value={hour} name="hour" id="hour">
      {#each availableHours as ahour}
        <option value={ahour}>{ahour}</option>
      {/each}
    </select>
  </form>

  <form action="" method="get" id="navigation">
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goPrev}
      value="Prev"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNow}
      value="Now"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNext}
      value="Next"
    />
  </form>

  <div>
    <img
      src={url}
      alt="wettzell grafik"
      onerror="javascript:this.src='++plone++lmu.svelte.svelte/wtz-vis/noData.png'"
    />
  </div>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  form {
    max-width: 100%;
    max-height:auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    max-height: auto;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
